var initCanvas = require('_canvas.js');

function Karte() {
    this.animationContainer = document.getElementById("animation_container");
    this.markerImg = 'mxm_landkarte/assets/img/marker.png';
}

Karte.prototype = {
    constructor: Karte,

    build: function() {
        var that = this;

        that.loadScript('mxm_landkarte/assets/js/createjs-2015.11.26.min.js', function () {
            that.loadScript('mxm_landkarte/assets/js/canvas.js', initCanvas);
        });

        this.addMarker();
    },

    hide: function() {
        this.animationContainer.style.display = 'none';
    },

    show: function () {
        this.animationContainer.style.display = 'block';
    },

    addMarker: function() {
        var articles = document.querySelectorAll('#content > article');
        var that = this;
        for (var i = 0; i < articles.length; i++) {
            var markerData = articles[i];
            var left = markerData.getAttribute("data-marker-x");
            var bottom = markerData.getAttribute("data-marker-y");
            if (left && bottom) {
                var markerWrapper = document.createElement("div");
                markerWrapper.setAttribute("class", "marker");
                markerWrapper.style.left = left;
                markerWrapper.style.bottom = bottom;
                
                var marker = new Image(30, 43);
                markerWrapper.setAttribute("data-id", markerData.getAttribute("id"));
                marker.src = that.markerImg;
                markerWrapper.appendChild(marker);

                var number = document.createElement("span");
                number.innerHTML = i;
                markerWrapper.appendChild(number);

                that.animationContainer.appendChild(markerWrapper);
            }
        }

        var allmarker = document.querySelectorAll('.marker');
        for (var i = 0; i < allmarker.length; i++) {
            allmarker[i].addEventListener('click', function (event) {
                var marker = event.target;
                if (!marker.classList.contains('marker')) {
                    marker = marker.parentNode;
                }
                that.hideAllContent();
                var activeMarker = document.querySelector('.marker.active');
                if (activeMarker) {
                    activeMarker.classList.remove('active');
                }
                marker.classList.add('active');
                document.getElementById(marker.getAttribute("data-id")).setAttribute("data-show", "true");
            });
        }

        var closer = document.querySelector('#content a[data-hide="content"]');
        closer.addEventListener("click", function (event) {
            event.preventDefault();
            that.hideAllContent();
        })
    },

    hideAllContent: function() {
        var content = document.querySelectorAll('#content > article');
        for (var i = 0; i < content.length; i++) {
            content[i].setAttribute("data-show", "false");
        }
    },

    loadScript: function (scriptFile, callback) {
        var script = document.createElement('script');
        script.onload = function () {
            callback();
        };
        script.src = scriptFile;
        document.head.appendChild(script);
    }
}

module.exports = Karte;