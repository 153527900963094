var info = document.querySelector('.info-icon');
info.addEventListener("click", function (event) {
    event.preventDefault();
    var icon = event.target;
    if (!icon.classList.contains('info-icon')) {
        icon = icon.parentNode;
    }
    hideAllContent();
    showContent(icon);
})

info.addEventListener("mouseover", function (event) {
    var icon = event.target;
    if (icon.classList.contains('info-icon')) {
        icon = icon.querySelector('[data-hover]');
    }

    icon.setAttribute('src', icon.getAttribute('data-hover'));
})

info.addEventListener("mouseleave", function (event) {
    var icon = event.target;
    if (icon.classList.contains('info-icon')) {
        icon = icon.querySelector('[data-hover]');
    }
    icon.setAttribute('src', icon.getAttribute('data-src'));
})

 function hideAllContent () {
     var content = document.querySelectorAll('#content > article');
     for (var i = 0; i < content.length; i++) {
         content[i].setAttribute("data-show", "false");
     }
 }

 function showContent(target) {
    document.getElementById(target.getAttribute("data-id")).setAttribute("data-show", "true");
 }import Karte from '_karte';
import Preloader from '_preloader';

var karte;
var preloader;

document.addEventListener("DOMContentLoaded", function () {
    /**
     * if mobile, load images and gifs
     * if desktop, build map canvas
     */
    if (isMobile()) {
        preloader = new Preloader();
        preloader.loadImages();
    } else {
        karte = new Karte();
        karte.build();
    }

    window.onresize = function () {
        /**
         * if mobile, and map already loaded, hide it
         */
        if (karte !== undefined && isMobile()) {
            karte.hide();
        }

        /**
         * if mobile, load images and gifs
         * if desktop, build map canvas or show it, if already exists
         */
        if (isMobile()) {
            if (preloader === undefined) {
                preloader = new Preloader();
                preloader.loadImages();
                preloader.loadGifs();
            } 
        } else {
            if (karte === undefined) {
                karte = new Karte();
                karte.build();
            } else {
                karte.show();
            }
        }
    };

    setUserAgent();
});

function setUserAgent() {
    var doc = document.documentElement;
    doc.setAttribute('data-useragent', navigator.userAgent);
}

function isMobile() {
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
    return windowWidth <= 767;
}

/**
 * if window loaded, disable spinner
 */
window.onload = function () {
    setTimeout(function() {
        document.querySelector(".mxm_landkarte").classList.add("initialized");
    }, 200);

    if (preloader !== undefined) {
        preloader.loadGifs();
    }
};