function Preloader() {
    this.preloads = document.querySelectorAll(".img-preload");
}

Preloader.prototype = {
    constructor: Preloader,

    loadImages: function () {
        /**
         * first, load jpgs to ensure images
         */
        var images = [];
        var that = this;
        for (var i = 0; i < this.preloads.length; i++) {
            var preload = this.preloads[i];
            var imgLink = preload.getAttribute('data-img');
            var parent = preload.parentNode;

            var img = new Image();
            img.src = imgLink;
            parent.insertBefore(img, preload);
            images.push(img);
        }
    },

    loadGifs: function () {
        /**
         * load gifs, if there is a valid path
         */
        for (var i = 0; i < this.preloads.length; i++) {
            var preload = this.preloads[i];
            var gifLink = preload.getAttribute('data-gif');
            var parent = preload.parentNode;
            
            if (gifLink) {
                parent.querySelector('img').remove();
                var img = new Image();
                img.src = gifLink;
                parent.insertBefore(img, preload);
            }
        }
    }
}

module.exports = Preloader;